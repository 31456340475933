/* Basic reset */
body, html {
    margin: 0;
    padding: 0;
}

/* Remove padding-top from body and just handle it in main */
body {
    font-family: Arial, sans-serif;
}

/* Header styles */
header {
    background: white;
    color: #808080;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 1001;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-bottom: 1px solid lightgray;
    height: 2.5rem;  /* Explicit height */
}

.header-content {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
}

.logo {
    font-size: 1.25rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

/* Menu button */
#menu-toggle, .spacer {
    background: none;
    border: none;
    color: darkgray;
    font-size: 2rem;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.spacer {
    opacity: 0;
}

#menu-toggle:focus {
    outline: none;
}

/* Navigation sidebar */
nav {
    position: fixed;
    width: 200px;
    background: #f9f9f9;
    left: -200px;
    transition: left 0.3s;
    top: 2.5rem;
    bottom: 0;
    overflow-y: auto;
    z-index: 1000;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    margin: 0;
    padding: 0;
}

nav ul li a {
    text-decoration: none;
    color: black;
    display: block;
    padding: 1rem 1.5rem;  /* Increased left padding */
    transition: background 0.3s;
}

/* Section headers in nav */
nav ul li.section-header {
    padding: 1.5rem 1.5rem 0.5rem;
    color: #666;
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
}

nav ul li a:hover {
    background: #ececec;
}

/* Overlay */
.overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0);
    z-index: 999;
}

.overlay.active {
    visibility: visible;
    opacity: 0.5;
}

main {
    padding-top: 2.5rem;  /* Exactly matches header height */
}

/* Responsive layout */
@media (min-width: 768px) {
    nav {
        left: 0;
    }
    main {
        margin-left: 200px;
    }
    #menu-toggle {
        display: none;
    }
}